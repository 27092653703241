<template>
  <span>
    К сожалению, мы ничего не нашли по вашему запросу. Возможно, стоит
    попробовать изменить ценовой диапазон или поискать специалистов в другом
    районе города. Если мы все равно не нашли то, что вы ищете, пожалуйста,
    напишите нам об этом.
  </span>
</template>
<script>
const COMPONENT_NAME = "main-result-not-found";
export default {
  name: COMPONENT_NAME,
};
</script>

<style scoped></style>
