<template>
  <div>
    <div v-if="showResult">
      <v-divider />
      <div class="overline mb-4">
        Найдено результатов {{ objResultClinic.length }}:
      </div>
      <template>
        <div v-for="obj in objResultClinic" :key="obj.departmentId">
          <v-card class="media" hover outlined>
            <div class="media-body">
              <v-row>
                <v-col
                  cols="3"
                  sm="2"
                  class="d-flex justify-center align-center"
                >
                  <v-avatar class="profile" color="grey" size="64" tile>
                    <v-img
                      v-if="obj.departmentAvatar"
                      :src="apiAddress + obj.departmentAvatar"
                      :alt="obj.departmentName + ', Волгоград'"
                    ></v-img>
                    <v-img v-else src="~images/base_img_doctor.webp"></v-img>
                  </v-avatar>
                </v-col>

                <v-col cols="7" sm="7">
                  <router-link
                    :to="{
                      path: '/department/' + obj.departmentUrl + '/' + obj.departmentId,
                      params: { depId: obj.departmentId, depName: obj.departmentUrl},
                    }"
                    target="_blank"
                  >
                    <h5 class="media-heading" itemprop="description">
                      {{ obj.departmentName }}
                    </h5>
                  </router-link>
                  <div class="department-address">
                    <span :class="b('department')">
                      {{ obj.departmentAddress }}
                    </span>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  sm="3"
                  class="btn-container d-flex align-center justify-center"
                >
                  <v-btn
                    outlined
                    class="mb-1"
                    color="primary"
                    @click="openDrawer(obj.departmentId)"
                  >
                    Подробнее
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </template>
      <!--Настроенная пагинация для раздела поиска (по клиникам)-->
      <!--      <div class="flex-shrink-0">-->
      <!--        <div-->
      <!--                class="pagination text-center pb-4 pt-3"-->
      <!--                v-show="objPagination.actual_count >= 2 && showResult"-->
      <!--        >-->
      <!--          <v-pagination-->
      <!--                  v-model="page"-->
      <!--                  :length="objPagination.actual_count"-->
      <!--                  :prev-icon="icons.mdiMenuLeft"-->
      <!--                  :next-icon="icons.mdiMenuRight"-->
      <!--                  @input="searchEntity"-->
      <!--          >-->
      <!--          </v-pagination>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <!--Если результатов поиска не найдено-->
    <div v-show="showNotResult">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <div v-else>
        <not-found />
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "store/names";
import isEmpty from "lodash/isEmpty";
import { mapGetters, mapActions } from "vuex";
import NotFound from "./MainResultNotFound";
// import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";

const COMPONENT_NAME = "main-result-clinic";
export default {
  name: COMPONENT_NAME,
  props: ["data"],
  created() {
    this.searchEntity(false);
  },
  data: () => ({
    loading: false,
    // page: 1,
    // icons: {
    //   mdiMenuRight,
    //  mdiMenuLeft,
    // },
  }),
  components: { NotFound },
  mounted() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 3000);
  },
  watch: {
    selectedSortValue() {
      this.searchEntity(true);
    },
    selectedCityParts() {
      this.searchEntity(true);
    },
  },
  computed: {
    ...mapGetters({
      selectedSortValue: names.SELECTED_SORT_VALUE,
      objResultClinic: names.OBJ_SEARCH_SERVICE_BY,
      selectedCityParts: names.SELECTED_CITY_PARTS,
      selectedCity: names.OBJ_SELECTED_CITY,
      objPagination: names.OBJ_SEARCH_PAGINATION,
    }),
    apiAddress() {
      return names.API_ADDRESS;
    },
    showResult() {
      return !isEmpty(this.objResultClinic);
    },
    showNotResult() {
      return this.data.entityName !== null && isEmpty(this.objResultClinic);
    },
  },
  methods: {
    ...mapActions({
      mainResultClinic: names.ACTION_MAIN_SEARCH_CLINIC,
      teleportDepartmentId: names.ACTION_SAVE_DEPARTMENT_ID,
      clearResult: names.ACTION_CLEAR_OBJ_SERVICE_BY,
    }),
    async searchEntity(update) {
      if (update === false) {
        this.clearResult();
      }
      this.mainResultClinic({
        clinicId: this.data.entityId,
        cityId: this.selectedCity.id,
        sort: this.selectedSortValue,
        cityPart: this.selectedCityParts,
        update: update,
        // page: this.page,
      });
    },

    openDrawer(departmentId) {
      this.$drawer.showDrawer(true, "Detail");
      document.querySelector("body").style.overflow = "hidden";
      this.teleportDepartmentId(departmentId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

span {
  color: #000;
  font-family: $sanspro;
  font-size: 14px;
}
.department-address {
  @include phone-only {
    padding-left: 15px;
  }
}

.media {
  text-align: left;
  margin-top: 15px;
  overflow: visible;
  padding: 10px;
  border: 1px solid #f2f2f2;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 6px 5px #e3ecfc;
  }

  a {
    text-decoration: none;
  }

  .media-heading {
    font-size: 20px;
    @include smaller-phone {
      padding-left: 20px;
    }
  }

  .btn-container {
    @include smaller-phone {
      margin-top: 20px;
    }

    button {
      width: 120px;
      height: 35px;
      font-size: 13px;
      background: initial;
      text-transform: none;
      @include phone-only {
        width: 100px;
        height: 35px;
      }
    }
  }
}
</style>
